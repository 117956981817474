import { Show, Suspense } from 'solid-js';
import { BreadcrumbItem, Breadcrumbs, Button, Container, Heading, Page, Section, TextLink, Link } from '@troon/ui';
import { createAsync, useLocation } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import { IconGolfCartCustom } from '@troon/icons/golf-cart-custom';
import { useUser } from '../../providers/user';
import { Grid, GridFive, GridSeven } from '../../components/layouts/grid';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import PriveTeeTimeForm from '../rewards/visa(confirmation)/components/prive-tee-time-form';

export default function TroonAccessPriveRequestTeeTime() {
	const user = useUser();
	const location = useLocation();
	const facilities = createAsync(() => getFacilities({}), { deferStream: false });

	return (
		<Suspense>
			<>
				<Title>Request a Tee Time | Troon Privé Member Programs | Troon Access | Troon</Title>

				<div class="h-full grow bg-neutral-100">
					<Container>
						<div class="pt-4">
							<Breadcrumbs>
								<BreadcrumbItem href="/account">Account</BreadcrumbItem>
								<BreadcrumbItem href="/account/access">Troon Access</BreadcrumbItem>
								<BreadcrumbItem href={location.pathname}>Request a tee time</BreadcrumbItem>
							</Breadcrumbs>
						</div>

						<Page>
							<Grid>
								<GridFive>
									<Section>
										<IconGolfCartCustom class="size-24" />
										<Heading as="h1" size="h2">
											Request a Tee Time
										</Heading>

										<p>
											{' '}
											As a Troon Access+ member, you can request tee times at select Troon Prive private clubs. Rates
											vary by course. Select your desired club from the dropdown to see the applicable rate. New courses
											will be added over time.
										</p>

										<p>
											If you have any questions about the program or are experiencing issues accessing these benefits,
											please <TextLink href="/about/contact">contact us</TextLink>.
										</p>
									</Section>
								</GridFive>

								<GridSeven>
									<Show
										when={user()?.me.troonAccessProductType === 'TROON_ACCESS_PLUS'}
										fallback={<RequiresTroonAccessPlus />}
									>
										<PriveTeeTimeForm
											facilities={facilities()?.facilities.facilities ?? []}
											priveRequestType="TROON_ACCESS"
										/>
									</Show>
								</GridSeven>
							</Grid>
						</Page>
					</Container>
				</div>
			</>
		</Suspense>
	);
}

function RequiresTroonAccessPlus() {
	return (
		<Section appearance="contained">
			<Heading as="h2" size="h2">
				Requires Troon Access+
			</Heading>
			<p>Only Troon Access+ members can request tee times at select Troon Prive private clubs.</p>
			<Button as={Link} href="/access" class="mt-4">
				Get Troon Access+
			</Button>
		</Section>
	);
}

const facilityQuery = gql(`query troonAccessPriveTeeTimeFacilities {
	facilities: facilitiesV3(supportsTroonAccessPrive: true, sortBy: name) {
		facilities {
			id
			slug
			name
			timezone
		}
	}
}`);

const getFacilities = cachedQuery(facilityQuery, {});
